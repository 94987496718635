import React from 'react';
import { Container, InputElement } from './Checkbox.styles';
import cx from 'classnames';
import FeedbackError from '@/components/form/FeedbackError/FeedbackError';
import Label from '@/components/form/Label/Label';

export interface CheckboxProps {
  id?: string;
  label?: string;
  name: string;
  className?: string;
  error?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, label, name, error, className, ...props }: CheckboxProps, ref) => {
    return (
      <Container>
        <InputElement
          ref={ref}
          id={id || name}
          type="checkbox"
          name={name}
          className={cx(
            {
              'is-invalid': error,
            },
            className
          )}
          {...props}
        />
        <Label htmlFor={name}>{label}</Label>
        <FeedbackError error={error} />
      </Container>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
