import React, { ReactNode } from 'react';
import { Element } from './Label.styles';

export interface LabelProps {
  className?: string;
  children?: ReactNode;
  // onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
  htmlFor: React.LabelHTMLAttributes<HTMLLabelElement>['htmlFor'];
  required?: boolean;
}

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(({ children, ...props }: LabelProps, ref) => {
  return (
    <Element ref={ref} {...props}>
      {children}
    </Element>
  );
});

Label.displayName = 'Label';

export default Label;
