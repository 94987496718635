import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      gap: 0.5rem;
      justify-content: start;
      align-items: start;
      margin-bottom: 1rem;
    `;
  }};
`;

export const InputElement = styled.input`
  ${({ theme, ...props }) => {
    return css`
      background: ${theme.color.white};
      border-width: 2px;
      border-style: solid;
      border-radius: ${theme.radius.xs};
      appearance: none;

      width: 1rem;
      height: 1rem;
      padding: 0.5rem;

      // Style
      border-color: ${theme.color.neutral_400};
      border-radius: ${theme.radius.xs};
      outline: 0;

      // Transition
      transition: ${theme.transition.all};

      &:checked {
        border-color: ${theme.color.secondary_400};
        background-color: ${theme.color.secondary_400};
        background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.0612 10.0612L13.0612 26.0612C12.9218 26.2011 12.7563 26.312 12.5739 26.3877C12.3916 26.4635 12.1961 26.5024 11.9987 26.5024C11.8013 26.5024 11.6058 26.4635 11.4235 26.3877C11.2411 26.312 11.0756 26.2011 10.9362 26.0612L3.9362 19.0612C3.79667 18.9217 3.68599 18.7561 3.61047 18.5738C3.53496 18.3915 3.49609 18.1961 3.49609 17.9987C3.49609 17.8014 3.53496 17.606 3.61047 17.4237C3.68599 17.2414 3.79667 17.0758 3.9362 16.9362C4.07573 16.7967 4.24137 16.686 4.42367 16.6105C4.60598 16.535 4.80137 16.4961 4.9987 16.4961C5.19602 16.4961 5.39141 16.535 5.57372 16.6105C5.75602 16.686 5.92167 16.7967 6.0612 16.9362L11.9999 22.875L26.9387 7.93874C27.2205 7.65695 27.6027 7.49864 28.0012 7.49864C28.3997 7.49864 28.7819 7.65695 29.0637 7.93874C29.3455 8.22054 29.5038 8.60273 29.5038 9.00124C29.5038 9.39976 29.3455 9.78195 29.0637 10.0637L29.0612 10.0612Z" fill="white"/></svg>');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
      }
    `;
  }};
`;
