import styled, { css } from 'styled-components';
import { LabelProps } from './Label';

export const Element = styled.label.withConfig({
  shouldForwardProp: (prop) => !['required'].includes(prop),
})<LabelProps>`
  ${({ theme, required, ...props }) => {
    return css`
      display: block;
      font-size: ${theme.typography.font_size.text_sm};
      font-weight: ${theme.typography.font_weight.text_bold};

      ${required &&
      css`
        &:after {
          content: '*';
          color: ${theme.color.neutral_500};
          font-size: ${theme.typography.font_size.text_xs};
          vertical-align: top;
        }
      `};
    `;
  }};
`;
