import React from 'react';
import { Element } from '@/components/content/HtmlFormatter/HtmlFormatter.styles';

export interface HtmlFormatterProps {
  responsive?: boolean;
  dangerouslySetInnerHTML: React.DOMAttributes<HTMLDivElement>['dangerouslySetInnerHTML'];
  className?: string;
  supportLegacyContent?: boolean;
  align?: 'center';
}

const HtmlFormatter = React.forwardRef<HTMLDivElement, HtmlFormatterProps>(function HtmlFormatter(
  { dangerouslySetInnerHTML, className, ...props },
  ref: React.Ref<HTMLDivElement>
) {
  return <Element ref={ref} className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...props} />;
});

export default HtmlFormatter;
