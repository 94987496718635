import styled, { css } from 'styled-components';

const tableHeadBlock = css`
  display: block;
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.radius.xs};
`;

export const Element = styled.div<{ supportLegacyContent?: boolean; responsive?: boolean; align?: 'center' }>`
  ${({ theme, supportLegacyContent, responsive, align, ...props }) => {
    return css`
      ${align &&
      css`
        text-align: ${align}!important;
      `};

      ${responsive &&
      css`
        width: 100%;
        overflow-x: scroll;
      `};

      margin: ${theme.spacing.base} 0;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${theme.typography.font_family.title};
        font-weight: ${theme.typography.font_weight.title_semi_bold};
      }

      h2 {
        font-size: ${theme.typography.font_size.title_xl};
      }

      h3 {
        font-size: ${theme.typography.font_size.title_md};
      }

      p {
        margin: 0 0 ${theme.spacing.base} 0;

        a {
          &:not(.btn) {
            text-decoration: underline;
          }
        }
      }
      ol,
      ul {
        padding-left: ${theme.spacing.base};
        li {
          list-style: initial;
          margin-left: ${theme.spacing.base};
          ol,
          ul {
            li {
              margin-left: ${theme.spacing.base};
              list-style: circle;
            }
          }
        }
      }

      // -------------------------------
      // TODO Remove this in the future
      // Legacy Support
      // -------------------------------
      .text-center {
        text-align: center;
      }

      ${supportLegacyContent &&
      css`
        &.legacy--e-books {
          > div {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 1rem;
            @media screen and (min-width: ${theme.breakpoint.sm}) {
              grid-template-columns: repeat(2, 1fr);
            }
            @media screen and (min-width: ${theme.breakpoint.md}) {
              grid-template-columns: repeat(3, 1fr);
            }
            > div {
              align-items: center;
              justify-self: center;
              text-align: center;
              h2,
              h3 {
                color: ${theme.color.secondary_400};
                font-size: ${theme.typography.font_size.title_md};
                font-weight: ${theme.typography.font_weight.title_light};
              }
              img {
                border-radius: 0.25rem;
              }

              p {
                margin: 0;
              }
            }
          }
        }

        // ---------------------
        // TABLE LEGACY SUPPORT
        // TODO: REMOVE IN THE FUTURE
        // ---------------------

        // APPLIED AT DESTINO DETAIL
        h2 {
          color: ${theme.color.secondary_400};
          font-weight: ${theme.typography.font_weight.text_light};
        }

        .btn {
          vertical-align: middle;
          user-select: none;
          margin-block-start: 0;
          margin-block-end: 0;
          padding-block-start: 0;
          padding-block-end: 0;

          font-size: ${theme.typography.font_size.text_base};
          font-weight: ${theme.typography.font_weight.text_bold};
          padding: ${theme.spacing.sm} ${theme.spacing.base};
          text-align: center;

          // Style
          color: ${theme.color.white};
          border-color: ${theme.color.neutral_400};
          border-radius: ${theme.radius.xs};
          outline: 0;

          // Transition
          transition: ${theme.transition.all};
          &.btn-main {
            background: ${theme.color.secondary_400};
            background: ${theme.color.secondary_400};

            // States (default)
            &:hover {
              background: ${theme.color.secondary_600};
              color: ${theme.color.white};
            }

            &:focus {
              background: ${theme.color.secondary_600};
              border-color: ${theme.color.secondary_600};
              box-shadow: ${theme.box_shadow.sm};
            }
            &:active {
              background: ${theme.color.secondary_800};
            }
          }
        }

        hr {
          border: 0;
          border-bottom: 1px solid ${theme.color.neutral_200};
          margin: ${theme.spacing.base} 0;
        }

        .text-uppercase {
          text-transform: uppercase;
        }

        .price {
          color: ${theme.color.secondary_500};
          font-weight: bold;
          white-space: nowrap;
        }

        .table {
          font-size: 0.875rem;
          width: 100%;
          margin-bottom: 1rem;

          border: 1px solid ${theme.color.neutral_200};
          border-collapse: collapse;
          border-radius: 0.5rem;
          overflow: hidden;

          thead {
            th,
            td {
              border: 1px solid transparent !important;
              padding: 0;
              background: transparent;
              > span:not(.price_lux, .price_sup, .price_act, .price_btq),
              > strong > span:not(.price_lux, .price_sup, .price_act, .price_btq) {
                background: ${theme.color.neutral_200};
                ${tableHeadBlock};
              }

              > span,
              > strong span {
                ${tableHeadBlock};
              }
            }
          }

          th {
            border: 0 !important;
            background: transparent !important;

            // CUSTOM HEADER COLORS
            .price_lux,
            .price_sup,
            .price_act,
            .price_std,
            .price_btq {
              ${tableHeadBlock};
            }

            .price_lux {
              background: ${theme.color.primary_500} !important;
              color: ${theme.color.white} !important;
            }
            .price_sup {
              background: ${theme.color.secondary_500} !important;
              color: ${theme.color.white} !important;
            }
            .price_act {
              background: ${theme.color.primary_100} !important;
              color: ${theme.color.secondary_500} !important;
            }
            .price_std {
              background: ${theme.color.secondary_400} !important;
              color: ${theme.color.white} !important;
            }
            .price_btq {
              background: ${theme.color.primary_600} !important;
              color: ${theme.color.white} !important;
            }
          }

          td {
            padding: 0.275rem;
            border-bottom: 1px solid ${theme.color.neutral_200};
          }

          tbody {
            border-bottom: 3px solid ${theme.color.neutral_200} !important;
            tr:first-child {
              th {
                > span,
                > strong > span {
                  ${tableHeadBlock};
                  background: ${theme.color.neutral_200};
                }
              }
            }

            tr {
              //td[rowspan] {
              //  border-left: 1px solid ${theme.color.neutral_200};
              //td[rowspan]:not(:first-of-type) {
              //  border-bottom: 0;
              //}
              td[rowspan]:last-child {
                border-left: 1px solid ${theme.color.neutral_200};
              }
              td[rowspan]:not(:last-of-type) {
                border-left: 1px solid ${theme.color.neutral_200};
                border-right: 1px solid ${theme.color.neutral_200}; !important;
              }
              &:last-of-type,
              &:last-child {
                td {
                  border: 0;
                }
              }
            }
          }
        }
      `}
    `;
  }};
`;
