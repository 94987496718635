import React, { MouseEvent, ReactNode, useRef, useState } from 'react';
import useSWR, { Fetcher } from 'swr';
import { API_URL } from '@/helpers/browser';
import { Modal, ModalBody, ModalHeader } from '@/components/modal';
import { Element, FooterWraper, HeaderWrapper } from '@/components/popup/Popup.styles';
import Title from '@/components/typography/Title/Title';
import HtmlFormatter from '@/components/content/HtmlFormatter/HtmlFormatter';
import LogoIcon from '../logo/LogoIcon';
import ModalFooter from '../modal/ModalFooter/ModalFooter';
import Button from '@/components/button/Button/Button';
import Checkbox from '@/components/form/Checkbox/Checkbox';
import { setCookie } from '@/utils/cookie';
import dayjs from 'dayjs';

export interface PopupProps {
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

type PopupDataType = {
  title: string;
  content: string;
};

type DataResponse = {
  data: PopupDataType;
};

const fetcher: Fetcher<DataResponse, any> = (path: string) => {
  return fetch(path, {
    method: 'GET',
    credentials: 'include',
    // mode: 'cors',
  }).then((res) => res.json());
};

const Popup = ({ children, ...props }: PopupProps) => {
  const { data, isLoading, error, mutate } = useSWR(API_URL.popup, fetcher, {
    refreshInterval: 100000,
  });

  const checkboxRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(!data?.data);
  const toggle = () => {
    setOpen((prevState) => !prevState);
    handleClose();
  };

  const handleClose = async () => {
    if (checkboxRef?.current?.checked === true) {
      setCookie('_ktHasSeenPopIn', 'true', dayjs().add(7, 'days').toDate());
      await mutate();
    }
  };

  if (isLoading || !data?.data) return null;

  return (
    <Modal id="popup" open={open} toggle={toggle} position="center">
      <ModalHeader>
        <HeaderWrapper>
          <LogoIcon width={50} height={50} />
          <Title as="h4">Comunicado</Title>
        </HeaderWrapper>
      </ModalHeader>
      <ModalBody>
        <Element>
          <Title as="h4" size="xl" color="secondary_500" weight="semi_bold">
            {data?.data.title}
          </Title>
          <HtmlFormatter dangerouslySetInnerHTML={{ __html: data?.data.content || '' }} />
        </Element>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <FooterWraper>
          <Checkbox ref={checkboxRef} name="hide-popup" label="Não exibir esta mensagem novamente" />
        </FooterWraper>
        <Button size="base" onClick={toggle}>
          FECHAR
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Popup;
