import styled, { css } from 'styled-components';
import { PopupProps } from './Popup';

export const HeaderWrapper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      align-items: center;
      gap: 1rem;
    `;
  }};
`;

export const FooterWraper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      > div {
        margin-bottom: 0;
      }
    `;
  }};
`;

export const Element = styled.div<PopupProps>`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex-shrink: 0;
      gap: ${theme.spacing.sm};
      margin: 0 auto;
      max-width: 40rem;

      button {
        display: block;
      }

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        flex-direction: row;

        > div:first-child {
          flex-grow: 1;
        }
      }
    `;
  }};
`;
